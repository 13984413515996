.main {
  position: fixed;
  bottom: 0;
  width: 100vw;
  display: flex;
  margin-bottom: 0 !important;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
  background-color: none;
}

.main a {
  background-color: #f2f2f2;
}
.main img {
  width: 12vw;
}

@media only screen and (max-width: 800px) {
  .main {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 5vh;
    display: flex;
    background-color: #f2f2f2;
    margin-bottom: 0 !important;
    align-items: center;
    justify-content: center;
    z-index: 150;
  }

  .main img {
    width: 20vh;
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {
  .main {
    height:7.5vh;
  }
  .main img {
    width: 25vh;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .main img {
    width: 15vw;
  }
}
