.sectiondiv {
  padding-left: 0 !important;
  width: 30vw;
  margin-left:10vw;
  margin-bottom:5vh;
}

.cutdiv {
  font-size: fit-content;
  text-align: center;
  height: 60px;
  width: 23vw;
  display: flex;
  align-items: center;
  padding-left: 1vh;
  margin-right:10px;
  margin-bottom:6vh;
  /* background-image: url("../../assets/sectionbg.svg"); */
}

.name {
  font-weight: 600;
  color: #3a5385;
  background-color:#F2F2F2;
  text-align:left;
}

/* .cutdiv:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 57px solid #075594;
  border-left: 57px solid rgba(222, 95, 4, 1);
} */

/* .cutdiv2 {
  background: #0e111f;
  font-size: fit-content;
  text-align: center;
  font-weight: 600;
  height: 57px;
  position: absolute;
  width: 43%;
  z-index: -10;
} */

/* .cutdiv2:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 57px solid #0e111f;
  border-left: 57px solid #075594;
} */

.categoryimg {
  margin-right: 3vh;
  width: 5vh;
}

.container {
  margin-bottom: 3vh;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .sectiondiv {
    width: 50vw;
    margin-left:20vw;
  margin-bottom:3vh;
  }
  .cutdiv {
    width: 30vw;
  }
  .name {
  font-weight: 600;
  color: #3a5385;
  background-color:#F2F2F2;
}
}

@media (min-width: 1001px) and (max-width: 1200px) {
  .sectiondiv {
    width: 60vw;
    margin-left:15vw;
  margin-bottom:3vh;
  }
  .cutdiv {
    width: 40vw;
  }
}
@media (max-width: 800px) {

}
@media (min-width: 801px) {
  .sectionlines {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 4%;
    min-width: 100%;
    min-height: 100%;
    width: 90% !important;
  }
}

@media (max-width: 800px) {
  .sectionlines {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8%;
    min-width: 50%;
    min-height: 50%;
  }
    .sectiondiv {
    width: 80vw;
    margin-top:12px;
    margin-left:0vw;
  margin-bottom:0vh;
  }
  .cutdiv {
    width: 80vw;
  }
}

.topline {
  /* background-color: #01a369; */
  
  width: 70%;
  margin-bottom: 8px;
}
.bottomline {
  background-color: #3a5385;
  border: 4px solid #3a5385;
  width: 80%;
  min-height: 10%;
}

@media (max-width: 500px) {
  .name {
    font-size: 6vw !important;
  }
  .cutdiv {
    width: 63vw;
  }
}
