body {
  margin: 0 !important;
  padding: 0 !important;
  background-color: #F2F2F2;
  /* overflow: hidden; */
  background-image: url("./assets/home_background.svg");
  background-repeat: no-repeat;
  /* background-position:right bottom; */
  background-size:50%;
  background-attachment: fixed;
  background-position:right bottom;


}

@media (max-width:900px){
  body {
    background-size:70%;
    background-position:bottom right;
  }
}

@media (max-width:600px){
  body {
    background-size:90%;
    background-position:bottom right;
  }
}


@media (max-width:400px){
  body {
    background-size:120%;
    background-position:bottom right;
  }
}

.content {
  width: 100rem;
}

@media only screen and (max-width: 900px) {
  .my_content {
    /* width: 80vw; */
    margin-top: 10vh;
  }
  .my_dialog {
    width: 80vw;
    margin-top: 10vh;
  }
}
