@media screen and (max-width: 1000px) {
    /* .social_div {
      position: inherit;
      width: 6vw;
      background: #de5f04;
      z-index: 3;
    }
  
    .social_container {
      display:block!important;
      padding: 5px;
      display: flex;
      flex-direction: row;
      border-radius:15px;
      padding:10px 5px;
      position: relative;
      width: 90vw;
      background: #de5f04;
      z-index: 5;
      transition: 0.4s ease-in-out;
      align-items: center;
    }
   */
  
  
    /* .social_container:hover{
          transform:translate(-7vw,0);
      } */
      .social_icons{
          position: relative;
          bottom:0;
          display:flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom:14vh;
      }
      .social_icons img{
        height:5vw;
      }
   
  }
  
  @media screen and (min-width: 800px) {
    .social_container {
      display: none!important;
    }
  }
  