.event_container {
  /* padding-left: 0 !important; */
  /* overflow-y: scroll; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.scroll {
  /* height: 60vh; */
  /* overflow-y: scroll !important; */
  /* margin-bottom: 8vh; */
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  /* margin-left: 2vw; */
  padding-right: 7vw;
  /* justify-content: space-evenly; */
  justify-content: flex-start;
}

@media (max-width: 1200px) and (min-width: 1001px) {
  /* .my_col_12 {
    width: 110%;
  } */
  .scroll {
    flex-wrap: none;
    width: 90vw;
    padding-right: 0vw;
  }
}

@media (max-width: 1000px) and (min-width: 801px) {
  .event_container {
    width: 100%;
    display: flex;
    justify-content: left;
  }
  .scroll {
    flex-wrap: none;
    width: 70vw;
    padding-right: 0vw;
  }
}

@media (max-width: 800px) {
  /* .my_col_12 {
    width: 110%;
  } */
  .scroll {
    flex-wrap: none;
    justify-content: flex-start;
    padding-right: 2vw;
    width: 90vw;
    margin-bottom: 10vw;
  }
}

.reg_link {
  color: white;
  background-color: #de5f04;
  height: 6vh;
  width: 20vh;
  margin-bottom: 30px;
  margin-top: 20px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: 0.25s;
  box-shadow: 3px 5px 10px rgba(59, 59, 59, 0.589);
  cursor: pointer;
}
.reg_link:hover {
  transform: scale(0.99);
  color: white;
  text-decoration: none;
  transition: 0.25s ease-in-out;
  font-size: 1.8vh;
  box-shadow: 2px 5px 5px rgba(59, 59, 59, 0.6);
}

.reg_link:active {
  box-shadow: 1px 3px 2px rgba(59, 59, 59, 0.65);
  transition: 0.25s ease-in-out;
}

.cross {
  cursor: pointer;
  height: 3.5vh;
  margin-top: 10px;
}

.my_content {
  background: #f2f2f2;
  padding: 0px !important;
}

.head {
  display: flex;
  justify-content: space-between;
  /* margin-top:2vh; */
  padding-left: 2vh;
  padding-right: 2vh;
}

.datehead {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2vh;
}

.singledate {
  width: 22%;
  text-align: center;
  color: white;
  background-color: #de5f04;
  height: 5.5vh;
  margin-bottom: 3vh;
  /* margin-top: 20px; */
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: 0.5s;
  box-shadow: 0px 3px 5px rgba(59, 59, 59, 0.589);
  cursor: pointer;
}

.singledate:hover {
  text-decoration: none;
  transition: 0.5s ease-in-out;
  font-size: 1.8vh;
  box-shadow: 2px 5px 5px rgba(59, 59, 59, 0.6);
  background-color: white;
  color: #de5f04;
  border: 4px solid #de5f04;
}

.active {
  box-shadow: 0px 3px 2px rgba(59, 59, 59, 0.65);
  transition: 0.25s ease-in-out;
  background-color: white;
  color: #de5f04;
  border: 4px solid #de5f04;
}

.eventcontainer {
  margin: 3vh 4vh;
}

.eventname {
  font-weight: 500;
  font-size: 2rem;
}

.eventdetails {
  display: flex;
  justify-content: space-between;
  margin-top: 4vh;
}

.eventlink a {
  text-decoration: none;
  color: #000;
}

.eventlink {
  display: flex;
}

.eventlink img {
  height: 3vh;
}
.domain {
  width: 25%;
}

.platform {
  display: flex;
  justify-content: center;
}

.platform img {
  height: 4vh;
  z-index: 10;
}

.image {
  margin-right: 1.5vh;
}

@media (max-width: 1000px) {
  .eventname {
    font-size: 20px;
  }
  .domain {
    font-size: 12px;
    text-align: center;
  }
  .image {
    height: 2vh;
  }
  .platform img {
    height: 2.5vh;
    z-index: 10;
  }

  .eventlink a {
    text-decoration: none;
    color: #000;
  }

  .eventlink {
    display: flex;
    font-size: 14px;
  }

  .eventlink img {
    height: 2.5vh;
  }
  .eventcontainer {
    margin: 3vh 2vh;
    margin-right: 1vh;
  }
  .domain {
    width: 30%;
  }
}

@media (max-width: 500px) {
.eventname {
    font-size: 20px;
  }
  .domain {
    font-size: 10px;
    text-align: center;
  }
  .image {
    height: 1.5vh;
  }
  .platform img {
    height: 2vh;
    z-index: 10;
  }

  .eventlink a {
    text-decoration: none;
    color: #000;
  }

  .eventlink {
    display: flex;
    font-size: 10px;
  }

  .eventlink img {
    height: 2vh;
  }
  .eventcontainer {
    margin: 3vh 2vh;
    margin-right: 1vh;
  }
  .domain {
    width: 20%;
  }

}