.logo {
  display: flex;
  background: none;
  /* padding-left: 1vw; */
  align-items: center;
  justify-content: center;
  margin-top: -4vh;
  margin-right: 8vh;
}

.gif {
  height: 16vw;
  width: 16vw;
}

.aaruushlogo {
  height: auto;
  width: 43vw;
}
@media (max-width: 1200px) and (min-width: 1001px) {
  .logo {
    justify-content: center;

    margin-top: -2vh;
    margin-left: 0vh;
    margin-right: 0vh;
  }
  .gif {
    height: 16vw;
    width: 16vw;
  }

  .aaruushlogo {
    height: auto;
    width: 50vw;
  }
}

@media only screen and (max-width: 800px) {
  .logo {
    justify-content: center;

    margin-top: -2vh;
    margin-left: 0vh;
    margin-right: 0vh;
  }
  .gif {
    height: 25vw;
    width: 25vw;
  }

  .aaruushlogo {
    height: auto;
    width: 60vw;
  }
}

@media (max-width: 1000px) and (min-width: 801px) {
  .logo {
    justify-content: center;
    margin-top: -2vh;
    /* margin-left: 7vh; */
  }
}
