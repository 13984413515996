.main:hover {
  cursor: pointer;
}

.image {
  height: 70%;
}

.bottom {
  display: flex;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

.CR {
  position: absolute;
  right: -4.5vh;
  top: -1.5vh;
  font-size: 14px;
  padding: 5px;
  color: #f2f2f2;
  background: #2e3a59;
  border-radius: 1rem;
}

.registernow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.posterimg {
  float: right;
  margin-left: 10%;
  height: 400px;
}

.sponsorimages {
  display: flex !important;
  flex: wrap !important;
}

.desc {
  display: flex;
  justify-content: space-between;
  color: #f2f2f2;
}

.event_container {
  display: flex;
  padding: 10px;
  justify-content: space-around;
  align-items: center;
}

.cross {
  cursor: pointer;
  margin-left: 15px;
}

.modalcontainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.5fr;
  grid-gap: 3vh;
  padding-top: 3vh;
  padding-left: 1vh;
  padding-right: 1vh;
  padding-bottom: 1vh;
  overflow: hidden;
  background-image: url("../../assets/background.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #f2f2f2;
}

.my_content {
  background: #f2f2f2;
  padding: 0px !important;
}

.eventinfo {
  grid-row: 1/3;
  grid-column: 1/2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 2vh;
  /* height:50%; */
}

/* .eventdata{
  width:50%;
} */

.eventsponsors {
  padding-left: 1vh;
  grid-row: 3/4;
  grid-column: 1/2;
  z-index: 2;
}

.sponsorimages {
  display: flex;
  height: 80%;
  flex-wrap: wrap;
  background-color: #f2f2f2;
  border-radius: 1rem;
  padding: 2vh;
  height: fit-content;
}
.sponsorimages img {
  z-index: 100;
  height: 30%;
  width: 30%;
  margin: 2vh;
}

.eventposter {
  grid-row: 1/2;
  grid-column: 2/3;
}

.eventposter img {
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
}
.eventcarousel {
  display: flex;
  height: auto;
  width: 100%;
}
.eventdetailscontainer {
  grid-row: 3/4;
  grid-column: 2/3;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.eventdetails {
  background-color: #f2f9ff;
  border-radius: 0.5rem;
  padding: 2vh;
  height: fit-content;
  z-index: 2;
}

.detail {
  display: flex;
  align-items: center;
  color: #2e3a59;
}
.detail img {
  margin: 1vh;
  color: #2e3a59;
  height: 18px;
  width: 20px;
}
.platform img {
  margin: 0.5vh;
  color: #2e3a59;
}
.detail h6 {
  margin-left: 0.5vh;
  margin-top: 1vh;
  color: #2e3a59;
}

.background {
  z-index: 0;
  grid-row: 2/4;
  grid-column: 1/3;
  opacity: 0.5;
}

.caption {
  font-size: 2.5vh;
  margin: 1vh;
  margin-bottom: 0vh;
  /* height: 50vh !important; */
  overflow: scroll;
  padding-top: 1fvh;
  margin-top: 3vh;
}

.description {
  margin: 1vh;
  /* font-weight: 300 !important; */
  font-size: 2vh !important;
}

.eventname {
  margin-bottom: 1vh;
  margin-left: 1vh;
  font-size: 4vh;
}

.eventimage {
  height: fit-content;
  width: fit-content;
}

.name {
  font-size: 2.5vw;
  color: #f2f2f2;
}

.sponsorName {
  font-size: 4vh !important;
}
.card_body {
  cursor: pointer;
}

.svg_fill {
  cursor: pointer;
  fill: black !important;
  margin-left: 5px;
}

.svg_fill:hover {
  animation: proceed 0.5s ease-in 0.1s infinite alternate;
}

/* socials section */
.socials {
  display: flex;
  align-items: center;
  z-index: 11;
}
.socials a {
  padding: 0 0.5em;
}

@keyframes proceed {
  to {
    transform: translate(10px, 0);
  }
}

.desc {
  font-size: 4vh;
}

@media (min-width: 500px) and (max-width: 1150px) {
  .name {
    font-size: 4vh !important;
  }
  .desc h5 {
    font-size: 2vh;
  }
}

@media (min-width: 300px) and (max-width: 500px) {
  .name {
    font-size: 5.5vw !important;
  }
  .desc {
    font-size: 2vh !important;
  }
}
@media only screen and (max-width: 1000px) {
  .modalcontainer {
    display: grid;
    grid-template-rows: 0.5fr;
    grid-template-columns: 2fr;
    grid-gap: 2vh;
    padding-top: 2vh;
    padding-left: 1vh;
    padding-right: 1vh;
    padding-bottom: 1vh;
    height: 90%;
    overflow: hidden;
    background-image: url("../../assets/background.svg");
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .eventposter {
    grid-row: 1/2;
    grid-column: 1/2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .eventposter img {
    height: 80%;
    width: 80%;
    border-radius: 0.5rem;
  }

  .bottom {
    display: block;
  }
  .eventsponsors {
    width: 100% !important;
    flex-wrap: none !important;
    margin: 20px;
  }
  .eventdetails {
    width: 100% !important;
  }
  .posterimg {
    width: 100% !important;
    float: none !important;
    margin-left: 0 !important;
  }
  .sponsorimages {
    display: flex !important;
    flex: wrap !important;
    width: 60%;
  }
  .eventdetails {
    padding: 50px 50px;
  }
  .eventdetailscontainer {
    grid-row: 4/5;
    grid-column: 1/2;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  .event_main {
    flex-direction: column;
  }

  .event_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .name {
    font-size: 5vw;
  }
  .desc {
    font-size: 3.5vw;
    display: flex;
  }

  .card {
    width: 60vw;
  }

  .eventinfo {
    grid-row: 2/3;
    grid-column: 1/2;
  }

  .eventsponsors {
    padding-left: 1vh;
    z-index: 2;
  }

  .eventdetails {
    background-color: #f2f9ff;
    border-radius: 0.5rem;
    padding: 2vh;
    height: fit-content;
    z-index: 2;
  }

  .caption {
    height: 90%;
  }
}
.modal_title {
  font-size: 1.8vw;
}

@media (max-width: 800px) {
  .card {
    width: 90vw;
  }
  .modal_title {
    font-size: 4vw;
  }
}

@media (max-width: 300px) {
  .posterimg {
    height: 100px;
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 4vh !important;
  }
  h4 {
    font-size: 3vh !important;
  }
}

@media (max-width: 1000px) {
  .sponsorimages {
    width: 75%;
  }
}

@media (max-width: 350px) {
  .sponsorimages {
    width: 90%;
  }
}

.card {
  box-shadow: 1px 5px 12px rgba(56, 56, 56, 0.5) !important;
  transition: 0.4s !important;
}

.card:hover {
  box-shadow: 2px 10px 20px rgba(56, 56, 56, 0.6) !important;
  transition: 0.4s ease-in-out !important;
  transform: scale(1.02);
}

.modal_title {
  font-weight: bold;
}

/* width */
.caption::-webkit-scrollbar {
  display: block;
  width: 5px;
}

/* Track */
.caption::-webkit-scrollbar-track {
  background: #f1f1f179;
}

/* Handle */
.caption::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.568);
}

/* Handle on hover */
.caption::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.514);
}

.reg_link {
  color: white;
  background-color: #de5f04;
  height: 6vh;
  width: 100%;
  margin-top: 20px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: 0.25s;
  box-shadow: 3px 5px 10px rgba(59, 59, 59, 0.589);
}
.reg_link:hover {
  transform: scale(0.99);
  color: white;
  text-decoration: none;
  transition: 0.25s ease-in-out;
  font-size: 2.3vh;
  box-shadow: 2px 5px 5px rgba(59, 59, 59, 0.6);
}

.reg_link:active {
  box-shadow: 1px 3px 2px rgba(59, 59, 59, 0.65);
  transition: 0.25s ease-in-out;
}

.reg_link img {
  margin-left: 10px;
}

/* .head_reg_link {
  color: white;
  background-color: #de5f04;
  height: 6vh;
  width: 30%;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  position: absolute;
  right: 2vw;
  justify-content: center;
  flex-direction: row;
  transition: 0.4s;
  box-shadow: 3px 5px 10px rgba(59, 59, 59, 0.589);
}

.head_reg_link:hover {
  transform: scale(0.99);
  color: white;
  text-decoration: none;
  transition: 0.4s ease-in-out;
  font-size: 2.3vh;
  box-shadow: 2px 5px 5px rgba(59, 59, 59, 0.6);
}

.head_reg_link:active {
  box-shadow: 1px 3px 2px rgba(59, 59, 59, 0.65);
  transition: 0.4s ease-in-out;
} */


.head_reglink {
  color: white;
  background-color: #de5f04 !important;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  border: none;
  margin-right: 10px;
  justify-content: center;
  flex-direction: row;
  transition: 0.2s;
  box-shadow: 3px 5px 10px rgba(59, 59, 59, 0.589);
}

.head_reglink:hover {
  transform: scale(0.99);
  transition: 0.2s ease-in-out;
  box-shadow: 2px 5px 5px rgba(59, 59, 59, 0.6);
}

.head_reglink:active {
  box-shadow: 1px 3px 2px rgba(59, 59, 59, 0.65) !important;
  border: none !important;
  transition: 0.2s ease-in-out;
}

.head_reglink:visited {
  border: none !important;
}
