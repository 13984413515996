@media screen and (min-width: 800px) {
  .social_div {
    position: inherit;
    height: 100vh;
    width: 6vw;
    clip-path: polygon(50% 0, 50% 100%, 0 50%);
    /* background: #de5f04; */
    transform: translate(-2.5vw);
    transition: 0.4s ease-in-out;
    z-index: 3;
  }

  .social_container {
    padding: 5px;
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    right: 0vw;
    top: 0px;
    width: 5.1vw;
    transition: 0.4s ease-in-out;
    /* background: #de5f04; */
    z-index: 5;
    
    align-items: center;
  }



  /* .social_container:hover{
        transform:translate(-7vw,0);
    } */

    .social_icons{
        position: fixed;
        bottom:0;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left:1.2vw;
        z-index:100;
        /* padding-bottom:12vh; */
    }
    .social_icons img{
        margin:1.5vh 0;
        cursor:pointer;
        height:37px;
        width:37px;
    }
    .bottomline{
      width:8px;
      background-color:#3a5385;
      height:25vh;
      border-top-left-radius:1rem ;
      border-top-right-radius:1rem ;
      opacity:0.9;
    }
 
}

@media (min-width: 1001px) and (max-width: 1200px) {
  .social_icons{
    padding-left: 3vw;
  }
.social_icons img{
        margin:1.5vh 0;
        cursor:pointer;
        height:40px;
        width:40px;
    }
}

@media screen and (max-width: 800px) {
  .social_container {
    display: none;
  }
  .social_icons{
    display: none;
  }
}

.fb{
  fill:#fff;
}

.social_icons a{
  transition:0.2s ease-in-out;
}

.social_icons a:hover{
  transform:scale(1.1);
  transition:0.2s ease-in-out;
}